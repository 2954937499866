import { required, minLength } from "vuelidate/lib/validators"
import { buildPricesValidator } from "./../helpers"

export default priceKeys => ({
  name: { required },
  time_slot_id: { required },
  price_rows: {
    required,
    minLength: minLength(1),
    $each: {
      car_class_id: { required },
      prices: buildPricesValidator(priceKeys)
    }
  }
})
